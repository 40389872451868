import { Accordion as AccordionTypes } from "@/cms/types/objects";
import { FC, useState } from "react";
import classNames from "classnames";
import { CaretDown, CaretUp } from "@phosphor-icons/react/dist/ssr";
import { SanityIcon } from "@/cms/icons/Icon";

export const Accordion: FC<
  AccordionTypes & JSX.IntrinsicElements["button"]
> = ({ title, text, className, children, index, icon, ...rest }) => {
  const [showScrollBox, setShowScrollBox] = useState(false);

  return (
    <div>
      <button
        className={classNames(
          className,
          "relative flex w-full flex-row items-center gap-2 rounded-t-lg bg-[#F2EFF0] py-4 pl-3 pr-14 hover:bg-[#E5E0E1] focus:[box-shadow:var(--cvr-focus)]",
          { "rounded-lg": !showScrollBox },
        )}
        data-testid={index ? "accordion-" + index : "accordion"}
        onClick={() => {
          setShowScrollBox(!showScrollBox);
        }}
        {...rest}
      >
        <SanityIcon data={{ ...icon }} size={24} />
        <span className="text-start">{title}</span>
        {showScrollBox ? (
          <CaretUp className={classNames("absolute right-4")} size={24} />
        ) : (
          <CaretDown className={classNames("absolute right-4")} size={24} />
        )}
      </button>
      {showScrollBox && (
        <div
          className={classNames(
            "prose animation-accordion-slide-down w-full max-w-none rounded-b-lg bg-[#F5F3F4] px-4 py-3",
          )}
        >
          {text}
          {children}
        </div>
      )}
    </div>
  );
};
