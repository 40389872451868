import {
  CRYSTALLIZE_IDS,
  SubscriptionProduct,
} from "@cappelendamm/e-commerce-services";
import { BookContent, Price, SubscriptionContent } from "./types";
import { getContributorsByType } from "@/contributors/selectors";
import { Contributor, ContributorType } from "@/contributors/types";

export const formatPrice = ({ value, type }: Price): string => {
  const recurring =
    type === "single"
      ? ""
      : ` ${
          type === "monthly"
            ? "per bok"
            : type === "initial"
              ? "for første utsendelse"
              : "per år"
        }`;

  return `${value},–${recurring}`;
};

export const isSeriesSubscription = (content: Partial<SubscriptionContent>) => {
  return (
    content.subscriptionType ===
    CRYSTALLIZE_IDS.SHAPE.PRODUCT.SERIES_SUBSCRIPTION.ID
  );
};

export const isSubscription = (content: Partial<SubscriptionContent>) => {
  return (
    content.subscriptionType === CRYSTALLIZE_IDS.SHAPE.PRODUCT.SUBSCRIPTION.ID
  );
};

export const getProductListTitle = (content: Partial<SubscriptionContent>) => {
  if (isSeriesSubscription(content)) return "Bøker i serien";
  if (isSubscription(content)) return "Bøker i abonnementet";
};

export const getCampaignBannerText = (subscription: SubscriptionProduct) => {
  const giftCampaign = subscription.gifts?.count ? true : false;
  const freeMonthCampaign = getIsSubscriptionPriceFree(
    subscription?.initialPrice?.price,
  );
  if (freeMonthCampaign) return "Første bok er gratis!";
  if (giftCampaign) return "Gavekampanje. Abonner i dag!";
  return null;
};

export const getIsSubscriptionPriceFree = (initialPrice?: number): boolean => {
  if (initialPrice === undefined) return false;
  if (initialPrice === 0) return true;
  return false;
};

export const getAuthors = (
  book?: Pick<BookContent, "contributors">,
): Partial<Contributor>[] => {
  if (!book || !book.contributors) return [];

  return getContributorsByType(book.contributors, ContributorType.AUTHOR);
};
