"use client";
import classNames from "classnames";
import {
  UseCarouselControllerConfig,
  useCarouselController,
} from "@cappelendamm/design-system";
import { FC } from "react";
import { getThemeClassName } from "@/theming/selectors";
import { generateArrayFromLength } from "@/utils/array";
import { ImageTheme } from "@/theming/types";
import { getStyleFromImageThemeOrExistingTheme } from "@/theming/getCSSProperties";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react/dist/ssr";

export type SlideshowProps = {
  config: UseCarouselControllerConfig;
  canvas?: JSX.IntrinsicElements["ul"];
  themeBasedOnFirstSlide?: ImageTheme;
};

const SlideshowControl: FC<JSX.IntrinsicElements["button"]> = ({
  className,
  children,
  ...rest
}) => (
  <button
    {...rest}
    aria-hidden="true"
    className={classNames(
      "absolute z-20 flex h-12 w-12 items-center justify-center rounded-full",
      className,
      getThemeClassName("secondary"),
    )}
  >
    {children}
  </button>
);

export const Slideshow = ({
  config,
  children,
  className,
  themeBasedOnFirstSlide,
  ...rest
}: SlideshowProps & JSX.IntrinsicElements["div"]) => {
  const {
    ref,
    onNext,
    onPrevious,
    nextEnabled,
    previousEnabled,
    index,
    onIndex,
  } = useCarouselController(config);
  return (
    <div
      {...rest}
      className={classNames(
        "relative flex w-full flex-col items-center justify-center sm:rounded",
        className,
      )}
    >
      <ul
        className={classNames(
          "z-10 flex max-h-screen w-full gap-4 sm:max-h-none",
          "hidden-scrollbar snap-x overflow-x-auto overflow-y-hidden scroll-smooth will-change-scroll", // Scroll management
          "[&>li]:min-w-full [&>li]:snap-center",
        )}
        ref={ref}
      >
        {children}
      </ul>
      <ul
        className="z-10 flex w-full items-center justify-center sm:rounded-b"
        aria-hidden="true" // All slides are read by the screen reader
      >
        {generateArrayFromLength(config.count).map((idx) => (
          <li key={idx}>
            <button
              onClick={() => onIndex(parseInt(idx, 10))}
              className="flex h-12 items-center justify-center p-2"
            >
              <span
                className={classNames(
                  "block h-2 w-2 rounded-full",
                  index === parseInt(idx, 10) ? "bg-black" : "bg-gray-500",
                )}
              ></span>
            </button>
          </li>
        ))}
      </ul>
      <div className="w-body absolute mx-auto flex h-full flex-col items-stretch justify-center">
        <SlideshowControl
          className={classNames(
            "bottom-16 left-4 sm:bottom-auto sm:left-0 sm:translate-x-1/2",
            {
              hidden:
                (!previousEnabled && index === 0) || index + 1 === config.count,
            },
          )}
          style={{
            backgroundColor: getStyleFromImageThemeOrExistingTheme(
              themeBasedOnFirstSlide,
              "text",
            ),
          }}
          disabled={!previousEnabled}
          onClick={onPrevious}
        >
          <ArrowLeft
            size={32}
            style={{
              color: getStyleFromImageThemeOrExistingTheme(
                themeBasedOnFirstSlide,
                "background",
              ),
            }}
          />
          <span className="sr-only">Forrige</span>
        </SlideshowControl>
        <SlideshowControl
          className={classNames(
            "bottom-16 right-4 sm:bottom-auto sm:right-0 sm:-translate-x-1/2",
            {
              hidden: !nextEnabled || index === 0,
            },
          )}
          style={{
            backgroundColor: getStyleFromImageThemeOrExistingTheme(
              themeBasedOnFirstSlide,
              "text",
            ),
          }}
          disabled={!nextEnabled}
          onClick={onNext}
        >
          <span className="sr-only">Neste</span>
          <ArrowRight
            size={32}
            style={{
              color: getStyleFromImageThemeOrExistingTheme(
                themeBasedOnFirstSlide,
                "background",
              ),
            }}
          />
        </SlideshowControl>
        <button
          onClick={onNext}
          disabled={index > 0}
          aria-hidden="true"
          className={classNames(
            "min-w-sm absolute bottom-16 z-10 flex w-fit items-center gap-2 rounded border-none px-3 py-2",
            getThemeClassName("secondary"),
            { hidden: index > 0 },
            "self-start md:self-center",
          )}
          style={{
            backgroundColor: getStyleFromImageThemeOrExistingTheme(
              themeBasedOnFirstSlide,
              "background",
            ),
            color: getStyleFromImageThemeOrExistingTheme(
              themeBasedOnFirstSlide,
              "text",
            ),
          }}
        >
          <span>Start</span>
          <ArrowRight size={24} />
        </button>
      </div>
    </div>
  );
};
