import React from "react";
import Image from "next/image";
import { getImageAspectRatio, getImageProps } from "@/cms/images/getImageProps";
import { ProductCardType } from "./productCardType";
import { getTrackingDataset } from "@/tracking/utils";
import { TrackingItem, TrackingLink } from "@/tracking/types";
import classNames from "classnames";
import { formatPrice, getAuthors } from "@/products/selectors";
import { getPageLink } from "@/navigation/selectors";
import { Anchor } from "@/navigation";

const ProductCard = ({
  item,
  index,
  universeTag,
  cssContents,
}: ProductCardType) => {
  const linkTracking = getTrackingDataset<TrackingLink & Partial<TrackingItem>>(
    {
      parentIndex: index,
      parentSection: "product_carousel",
      itemType: "link",
      href: item?.slug ?? "undefined",
      itemIndex: 0,
    },
  );

  const authors = getAuthors(item);

  const authorString =
    authors.map((author) => author?.name ?? "") ?? [].join(" og ");

  const ariaLabel = `${item?.name} av ${authorString} ${
    formatPrice({
      value: item.price?.value ?? -1,
      type: "single",
      currency: item.price?.currency ?? "NOK",
    }) + " kroner" || ""
  }`;

  const numberInSeries = item?.tags?.numberInSeries;
  const seriesName = item?.tags?.seriesName;

  const isSingleBookSalesActive = false;
  return (
    <li
      key={index}
      className={classNames({ contents: cssContents })}
      data-testid="product-carousel-item"
    >
      <Anchor
        {...linkTracking}
        aria-label={ariaLabel}
        {...getPageLink({ pageType: "book", params: item?.slug, universeTag })}
        className="self-end"
      >
        {item?.image && (
          <Image
            className="self-end rounded border-[0.5px] border-solid"
            {...getImageProps({
              ...item.image,
              ...getImageAspectRatio({
                width: 240,
                aspectRatio: item.image.asset.metadata.dimensions.aspectRatio,
              }),
            })}
          />
        )}
      </Anchor>
      <div className="mt-2.5 font-normal" aria-hidden={true}>
        {numberInSeries && seriesName && (
          <p className="text-xs opacity-80">{`${seriesName} (Bok ${numberInSeries})`}</p>
        )}
        <h3 data-testid={"product-carousel-section-book-title-" + index}>
          {item?.name}
        </h3>
      </div>
      <p
        className="pb-3 text-xs opacity-80"
        data-testid={"product-carousel-section-book-author-" + index}
        aria-hidden={true}
      >
        {(authors?.length ?? -1) > 0 && authors?.[0]?.name}
      </p>
      {isSingleBookSalesActive ? (
        <div className="border-t border-solid pt-3.5 opacity-80">
          <span className="font-bold">{`${item.price?.value} ${item.price?.currency}`}</span>
        </div>
      ) : (
        <div></div>
      )}
    </li>
  );
};

export default ProductCard;
