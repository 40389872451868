import { SanityImage } from "@/cms/images/types";
import { BookContent } from "@/products/types";
import { BookReviews } from "@/sections/ProductCarouselSection/ReviewCard/reviewCardType";
import { ArbitraryTypedObject } from "@portabletext/types";
import { SanityDocument } from "next-sanity";
import { PortableTextBlock } from "sanity";

export enum ContributorType {
  AUTHOR = "Forfatter",
  TRANSLATOR = "Oversetter",
  NARRATOR = "Oppleser",
  ILLUSTRATOR = "Illustratør",
}

export type ContributorLink = {
  _key: string;
  url: string;
  urlText: string;
};

export type Contributor = {
  name: string;
  image: SanityImage;
  biography: (PortableTextBlock | ArbitraryTypedObject)[];
  slug: string;
  contributorLinks: ContributorLink[];
  yearOfBirth: number;
  yearOfDeath?: number;
  contributorType: ContributorType;
} & Pick<SanityDocument, "_id" | "_type">;

export type AuthorBooks = BookContent & BookReviews;
