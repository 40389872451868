"use client";

import { RichButtonSection as RichButtonSectionTypes } from "./types";
import { FC } from "react";
import classNames from "classnames";
import { TrackingItem, TrackingSection } from "@/tracking/types";
import { getTrackingDataset } from "@/tracking/utils";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { SectionProps } from "../Section";
import { RichButton } from "@/components/RichButton";
import { ContentData } from "@/cms/types";

export type RichButtonSectionProps = ContentData<RichButtonSectionTypes> &
  Omit<SectionProps, keyof RichButtonSectionTypes>;

export const RichButtonSection: FC<
  RichButtonSectionProps & JSX.IntrinsicElements["section"]
> = ({
  data: {
    _id,
    internalTitle,
    richButtons,
    container,
    variant,
    theme,
    title,
    universeTag,
  },
  index,
}) => {
  const sectionTracking = getTrackingDataset<
    TrackingSection & Partial<TrackingItem>
  >({
    sectionId: _id ?? "section-id-unknown",
    sectionName:
      internalTitle?.replaceAll(" ", "-") ?? "accordion_section_name",
    sectionType: "accordionSection",
    sectionIndex: index,
  });
  return (
    <section
      className={classNames(
        "lg:mx-body bg-primary mb-12 flex flex-col items-center overflow-x-scroll px-[var(--body-mx)] sm:overflow-visible",
        { "lg:w-body lg:mx-body lg:px-0": container === "default" },
      )}
      {...sectionTracking}
      style={{
        ...(variant === "color" ? getCSSPropertiesTheme(theme) : {}),
      }}
      data-testid="rich-button-section"
    >
      <div className="lg:w-body p-3">
        <h3
          className={classNames("heading-03", {
            "text-[var(--fg-primary)]": variant === "color",
          })}
        >
          {title}
        </h3>
        <div className={classNames("flex flex-col gap-3 py-3")}>
          {richButtons?.map((richButton) => (
            <RichButton
              key={richButton._key}
              universe={universeTag ?? ""}
              index={index}
              {...richButton}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default RichButtonSection;
