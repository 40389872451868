import {
  ECommerceItem,
  ViewECommerceItemEvent,
  ViewECommerceItemListEvent,
} from "../ga-properties";
import { ProductContent } from "@/products/types";
import tracker from "../tracker";

export const trackViewECommerceItem = (product: Partial<ProductContent>) => {
  const eCommerceItem: ECommerceItem = {
    item_id: `${product?.sku}`,
    item_name: `${product?.name}`,
    index: 0,
    item_category: product?.type,
    quantity: 1,
  };

  const viewECommerceItemEvent: ViewECommerceItemEvent = {
    eventName: "view_item",
    ecommerce: {
      currency: product?.price?.currency
        ? product?.price?.currency
        : "NotACurrency",
      value: product?.price?.value,
      items: [eCommerceItem],
    },
  };
  tracker.trackEvent(viewECommerceItemEvent);
};

export const getViewEComItemListEvent = (
  products: ProductContent[] | undefined,
  _id?: string,
  internalTitle?: string,
  currentCarouselInteraction?: string,
) => {
  const eCommerceItemsList = (): ECommerceItem[] => {
    const itemList = products?.map((product, index) => {
      const eCommerceItem: ECommerceItem = {
        item_id: `${product?.sku}`,
        item_name: product.name,
        index: index,
        item_category: product.type,
        quantity: 1,
      };
      return eCommerceItem;
    });
    return [...(itemList as ECommerceItem[])];
  };

  const viewItemListEvent: ViewECommerceItemListEvent & {
    view_carousel?: string;
  } = {
    eventName: "view_item_list",
    view_carousel: currentCarouselInteraction,
    ecommerce: {
      item_list_name:
        internalTitle?.replaceAll(" ", "-") ?? "product-carousel-section_name",
      item_list_id: `product-carousel-section-${_id}`,
      items: [...eCommerceItemsList()],
    },
  };
  return viewItemListEvent;
};
