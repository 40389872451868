import { ArrowRight } from "@/assets";
import { ProductVariantList } from "@/cms/types/objects";
import { SelectorItem } from "@/components/ProductVariantSelect";
import { Anchor } from "@/navigation";
import { getPageLink } from "@/navigation/selectors";
import { formatPrice } from "@/products/selectors";
import { SubscriptionOverride } from "@/products/types";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { Theme } from "@/theming/types";
import { Button } from "@cappelendamm/design-system";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";
import classNames from "classnames";
import { CSSProperties, FC } from "react";

export const SeriesSubscriptionHeaderContent: FC<{
  subscription: SubscriptionProduct & Partial<SubscriptionOverride>;
  booksList: ProductVariantList;
  universeTag: string;
  startBookItem: SelectorItem;
  buttonTheme?: Theme;
  styleVariant?: string;
  price?: { priceTitle: string; priceSubtitle: string };
  onChange: (value: SelectorItem) => void;
}> = ({
  subscription,
  universeTag,
  startBookItem,
  price,
  buttonTheme,
  styleVariant,
}) => {
  return (
    <div className={classNames("overflow-visible")}>
      <div className="grid gap-1">
        <div className="flex py-4">
          {price ? (
            <div className="flex flex-col">
              <span className="heading-02">{price.priceTitle}</span>
              <span>{price.priceSubtitle}</span>
            </div>
          ) : (
            <span className="heading-02">
              {formatPrice({
                currency: subscription.recurringPrice.currency,
                value: subscription.recurringPrice.price,
                type: "monthly",
              })}
            </span>
          )}
        </div>

        <Button
          as={Anchor}
          {...getPageLink({
            pageType: "checkout",
            universeTag,
            params: subscription.sku,
            searchParams: { initialSku: startBookItem.key },
          })}
          className={classNames(
            "xs:w-full min-w-[239px] px-8 py-4 !no-underline sm:w-fit",
            {
              "!border-none !bg-[var(--bg-primary)] !text-[var(--fg-primary)] hover:!bg-[var(--hover)] hover:!text-[var(--text-hover)]":
                styleVariant === "color",
            },
          )}
          style={
            {
              "--text-hover": `color-mix(in srgb, var(--text-primary-contrast) 80%, black)`,
              "--hover": `color-mix(in srgb, var(--bg-primary) 80%, black)`,
              ...(styleVariant === "color"
                ? getCSSPropertiesTheme(buttonTheme)
                : {}),
            } as CSSProperties
          }
          variant="primary"
        >
          Bli abonnent <ArrowRight size={24} />
        </Button>
      </div>
    </div>
  );
};
