import React from "react";
import { getImageAspectRatio, getImageProps } from "@/cms/images/getImageProps";
import { SanityImage } from "@/cms/images/types";
import classNames from "classnames";
import Image from "next/image";

export const SubscriptionImage = ({
  images,
  parentElement,
}: {
  images: SanityImage[];
  parentElement?: string;
}) => {
  if (images.length < 2) return null;
  return (
    <div
      className={classNames(
        "flex h-full w-full items-center justify-center p-4",
        { rounded: parentElement === "subscriptionCampaignFocus" },
      )}
      style={{
        background: `linear-gradient(91deg, ${images[0].asset?.metadata?.palette?.darkMuted?.background}CC 0.98%, ${images[0]?.asset?.metadata?.palette?.dominant?.background}CC 49.18%)`,
      }}
    >
      <div className="ml-[-40px] flex rounded shadow-[0px_0px_8px_0px_rgba(39,_37,_37,_0.25)]">
        {images.map((image, index) =>
          image ? (
            <React.Fragment key={`${image.asset._id}-${index}`}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <Image
                className={classNames(
                  "mr-[-40px] block rounded md:hidden",
                  `z${index * 10 - 50}`,
                  {
                    "shadow-[4px_0px_8px_0px_rgba(39,_37,_37,_0.25)]":
                      index <= 1,
                  },
                )}
                {...getImageProps({
                  ...image,
                  ...getImageAspectRatio({
                    width: 132,
                    aspectRatio: 4 / 6,
                  }),
                })}
                key={`${image.asset._id}-${index}-mobile`}
              />
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <Image
                className={classNames(
                  "mr-[-40px] hidden rounded md:block",
                  `z${index * 10 - 50}`,
                  {
                    "shadow-[4px_0px_8px_0px_rgba(39,_37,_37,_0.25)]":
                      index <= 1,
                  },
                )}
                {...getImageProps({
                  ...image,
                  ...getImageAspectRatio({
                    width:
                      parentElement === "subscriptionCampaignFocus" ? 166 : 132,
                    aspectRatio: 4 / 6,
                  }),
                })}
                key={`${image.asset._id}-${index}-desktop`}
              />
            </React.Fragment>
          ) : null,
        )}
      </div>
    </div>
  );
};
