import { RichButton as RichButtonTypes } from "@/cms/types/objects";
import { FC } from "react";
import classNames from "classnames";
import { SanityIcon } from "@/cms/icons/Icon";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { getPageLinkFromLinkConfig } from "@/cms/links";
import { LinkButton } from "@/navigation";

export const RichButton: FC<RichButtonTypes & JSX.IntrinsicElements["a"]> = ({
  title,
  subtitle,
  icon,
  link,
  className,
  universe,
  index,
  ...rest
}) => {
  const { href, linkAs } = getPageLinkFromLinkConfig({
    link,
    universeTag: universe,
  });

  return (
    <LinkButton
      href={href}
      linkAs={linkAs}
      className={classNames(
        className,
        "relative flex w-[343px] flex-row items-center gap-4 rounded bg-white px-3 py-4 hover:bg-[#F9F9F9] focus:[box-shadow:var(--cvr-focus)] sm:w-[461px]",
      )}
      data-testid={index ? "rich-button-" + index : "rich-button"}
      {...rest}
    >
      {icon && <SanityIcon data={{ ...icon }} size={42} />}
      <div>
        <p className="text-start font-semibold">{title}</p>
        <p className="text-start text-sm">{subtitle}</p>
      </div>
      <ArrowRight className={classNames("ml-[auto]")} size={24} />
    </LinkButton>
  );
};
